<template>
  <div class="d-flex justify-content-center h-100 flex-row align-items-center">
    <uello-button class="mr-2" icon="pencil" iconSize="small" @click="onEdit" rounded />
    <uello-button
      color="red"
      class="mr-2"
      icon="delete"
      iconSize="small"
      @click="onDelete"
      rounded
    />
  </div>
</template>

<script>
import { UelloButton } from '@uello/componentello';

export default {
  name: 'addresses-actions-template',
  components: {
    UelloButton,
  },
  data() {
    return {
      id: null,
    };
  },
  methods: {
    onEdit() {
      this.$parent.$parent.$emit('on-edit', this.id);
    },
    onDelete() {
      this.$parent.$parent.$emit('on-delete', this.id);
    },
  },
  beforeMount() {
    //
  },
  created() {
    this.id = this.params.value;
  },
};
</script>

<style lang="scss">
.icon-adjust {
  position: relative;
  top: -2px;
}
</style>
