<template>
  <div class="uello-app-users-management h-100 mt-5">
    <div class="d-flex justify-content-end align-items-center px-3 pt-4 mb-3">
      <uello-button
        @click="createAddress"
        color="red"
        icon="plus-circle-outline"
        :text="$t('form.buttons.addAddress')"
      />
    </div>

    <dataset-ag-grid
      ref="partnerAddresses"
      :setData="() => {}"
      :defs="defs"
      :rowData="addresses"
      :dataComponents="dataComponents"
      :dataFwComponents="fwComponents"
      v-on:on-edit="onEdit"
      v-on:on-delete="onDelete"
    />

    <uello-modal
      v-if="showModal"
      @close="showModal = !showModal"
      :title="creating ? 'Novo endereço' : 'Editando: ' + newAddress.name"
      confirmLabel=""
      closeLabel=""
      size="lg"
    >
      <b-form class="my-4" @submit.prevent="handleSubmit">
        <b-row class="mx-0">
          <b-col cols="6" class="pl-0">
            <b-form-group
              :label="`${$t('form.labels.addressName')}:`"
              label-for="input-address-name"
              size="sm"
              class="mb-4"
            >
              <b-form-input
                class="mt-2"
                size="sm"
                id="input-address-name"
                v-model="newAddress.name"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mx-0">
          <b-col cols="2" class="pl-0">
            <b-form-group
              :label="`${$t('form.labels.postcode')}:`"
              label-for="input-address-postcode"
              size="sm"
              class="mb-4"
            >
              <b-form-input
                class="mt-2"
                id="input-address-postcode"
                v-model="newAddress.postcode"
                v-mask="'#####-###'"
                @blur="checkPostcode"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mx-0">
          <b-col cols="6" class="pl-0 pr-2">
            <b-form-group
              :label="`${$t('form.labels.address')}:`"
              label-for="input-address-address"
              size="sm"
              class="mb-4"
            >
              <b-form-input
                class="mt-2"
                id="input-address-address"
                v-model="newAddress.address"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="2" class="pl-0 pr-2">
            <b-form-group
              :label="`${$t('form.labels.streetNumber')}:`"
              label-for="input-address-streetNumber"
              size="sm"
              class="mb-4"
            >
              <b-form-input
                class="mt-2"
                id="input-address-streetNumber"
                v-model="newAddress.number"
                ref="streetNumber"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4" class="pl-0">
            <b-form-group
              :label="`${$t('form.labels.complement')}:`"
              label-for="input-address-complement"
              size="sm"
              class="mb-4"
            >
              <b-form-input
                class="mt-2"
                id="input-address-complement"
                v-model="newAddress.complement"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="5" class="pl-0 pr-2">
            <b-form-group
              :label="`${$t('form.labels.neighborhood')}:`"
              label-for="input-address-neighborhood"
              size="sm"
              class="mb-4"
            >
              <b-form-input
                class="mt-2"
                id="input-address-neighborhood"
                v-model="newAddress.neighborhood"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="5" class="pl-0 pr-2">
            <b-form-group
              :label="`${$t('form.labels.city')}:`"
              label-for="input-address-city"
              size="sm"
              class="mb-4"
            >
              <b-form-input
                class="mt-2"
                id="input-address-city"
                v-model="newAddress.city"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="2" class="pl-0">
            <b-form-group
              :label="`${$t('form.labels.uf')}:`"
              label-for="input-address-uf"
              size="sm"
              class="mb-4"
            >
              <b-form-input
                class="mt-2"
                id="input-address-uf"
                v-model="newAddress.state"
                v-mask="'AA'"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mx-0 justify-content-right pull-right">
          <b-button type="submit" variant="primary" class=" right mt-3" :disabled="loading">
            <span v-if="!loading">{{ $t('form.buttons.saveButton') }}</span>
            <b-spinner v-else variant="white" class="btn-spin" small></b-spinner>
          </b-button>
        </b-row>
      </b-form>
    </uello-modal>
    <uello-modal
      @close="showRemoveModal = !showRemoveModal"
      title="Remover Endereço?"
      v-if="showRemoveModal"
      size="md"
    >
      <b-row class="mx-0 my-4">
        <p>
          Confirmar a remoção do endereço: <b>{{ addressToRemove.name }}</b
          >?
        </p>
      </b-row>
      <b-row class="mx-0 justify-content-right pull-right">
        <uello-button
          class=" right mt-3"
          @click="removeAddress"
          color="red"
          :loading="loading"
          :text="$t('requestItems.confirm')"
          type="button"
        >
        </uello-button>
      </b-row>
    </uello-modal>
    <movidesk-modal
      :hasButton="movideskHasButton"
      :is-open="movideskIsOpen"
      :prefill="{}"
      @close="closeMovidesk"
      @open="openMovidesk"
    />
  </div>
</template>

<script>
import { movidesk } from '@/mixins';
import { UelloButton, UelloModal } from '@uello/componentello';
import api from '@/services/api';
import viacep from '@/services/viacep';
import i18n from '@/i18n';

import AddressesActionsCellRender from './components/u-addresses/AddressesActionsCellRender.vue';

export default {
  name: 'uello-app-favorite-addresses',
  mixins: [movidesk],
  components: {
    MovideskModal: () => import('@/components/MovideskTicketFormModal.vue'),
    UelloButton,
    UelloModal,
  },
  data() {
    return {
      loading: false,
      creating: false,
      showModal: false,
      modalOpt: {
        visible: false,
      },
      movideskHasButton: true,
      newAddress: {
        id: 0,
        name: '',
        postcode: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      dataComponents: {
        AddressesActionsCellRender,
      },
      fwComponents: {
        addressActions: 'AddressesActionsCellRender',
      },
      defs: [
        {
          headerName: 'Id',
          field: 'id',
          sortable: false,
          filter: false,
          hide: true,
        },
        {
          headerName: i18n.t('form.labels.name'),
          field: 'name',
          sortable: false,
          filter: false,
        },
        {
          headerName: i18n.t('form.labels.address'),
          field: 'address',
          sortable: false,
          filter: false,
        },
        {
          headerName: i18n.t('orderEditModals.infoDetails.created_at'),
          field: 'createdAt',
          cellStyle: { textAlign: 'center' },
          sortable: false,
          filter: false,
        },
        {
          headerName: i18n.t('orderEditModals.actions'),
          field: 'id',
          cellStyle: { textAlign: 'center' },
          sortable: false,
          filter: false,
          cellRenderer: 'addressActions',
        },
      ],
      addresses: [],
      addressToRemove: {
        id: 0,
        name: '',
      },
      showRemoveModal: false,
    };
  },
  created() {
    this.getAllAddresses();
  },
  methods: {
    async getAllAddresses() {
      this.addresses = [];

      const { data } = await api.get('/partner/address');

      if (data.success) {
        this.addresses = data.data;
      }
    },
    onEdit(id) {
      this.loading = false;
      this.creating = false;

      this.addresses.forEach(address => {
        if (address.id === id) {
          this.newAddress = { ...address.detail };
        }
      });

      this.showModal = true;
    },
    onDelete(id) {
      this.addressToRemove.id = 0;
      this.addressToRemove.name = '';
      this.addresses.forEach(address => {
        if (address.id === id) {
          this.addressToRemove.id = address.id;
          this.addressToRemove.name = address.name;
        }
      });

      if (this.addressToRemove.name !== '') {
        this.showRemoveModal = true;
      }
    },
    async removeAddress() {
      this.loading = true;

      try {
        const { id } = this.addressToRemove;
        const { data } = await api.delete(`/partner/address/${id}`);

        this.showRemoveModal = false;
        if (data.success) {
          await this.getAllAddresses();
          this.$toast.success(this.$t(`messages.${data.message}`));
          return;
        }

        this.$toast.error(this.$t(`messages.${data.message}`));
      } catch (error) {
        this.$toast.error(this.$t('messages.errorOnProcess'));
      }

      this.loading = false;
    },
    createAddress() {
      this.newAddress.name = '';
      this.newAddress.postcode = '';
      this.newAddress.address = '';
      this.newAddress.number = '';
      this.newAddress.complement = '';
      this.newAddress.neighborhood = '';
      this.newAddress.city = '';
      this.newAddress.state = '';
      this.loading = false;
      this.creating = true;
      this.showModal = true;
    },
    async handleSubmit() {
      this.loading = true;

      try {
        const { data } = await api[this.creating ? 'post' : 'put'](
          '/partner/address',
          this.newAddress
        );

        await this.getAllAddresses();

        this.showModal = false;

        if (data.success) {
          this.$toast.success(this.$t(`messages.${data.message}`));
          this.loading = false;
          return;
        }

        this.$toast.error(this.$t(`messages.${data.message}`));
      } catch (error) {
        const msg = this.creating ? this.$t('messages.newAddressFailed') : 'Error';
        this.$toast.error(msg);
      }

      this.loading = false;
    },
    async checkPostcode() {
      if (this.newAddress.postcode.length === 9) {
        try {
          const postcode = this.newAddress.postcode.replace(/-/g, '');
          const { data } = await viacep.get(`/${postcode}/json`);

          if (data.erro) {
            throw data;
          }

          this.newAddress.address = data.logradouro;
          this.newAddress.number = '';
          this.newAddress.complement = '';
          this.newAddress.neighborhood = data.bairro;
          this.newAddress.city = data.localidade;
          this.newAddress.state = data.uf;

          this.$refs.streetNumber.$el.focus();
        } catch (error) {
          this.$toast.error('CEP inválido');
        }
      }
    },
  },
};
</script>

<style>
.pull-right {
  float: right;
}
</style>
